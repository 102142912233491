import React, { useCallback, useEffect, useState } from 'react';
import { Form, Select, Checkbox, notification } from 'antd';
import { ArrayResult, Properties } from 'types';
import { AxiosError } from 'axios';
import credits, { NomenclaturesCredits } from 'lib/api/credits';

import { formItemStyle } from '../helpers';
import { Link } from '../Custom';

enum CalculatorType {
  DEPOSIT = 'deposit',
  CREDIT = 'credit',
}

const paramsEntries = new URLSearchParams(location.search).entries();
const params = Object.fromEntries(paramsEntries);

const page = Number(params.page) || 1;

export default ({ item, lang, setFieldsValue }: Properties) => {
  const [data, setData] = useState<ArrayResult<NomenclaturesCredits>>();

  const fetchCredits = useCallback(async () => {
    try {
      setData(
        await credits.getCredits({
          ...params,

          page,
        }),
      );
    } catch (e) {
      notification.error({
        message: 'Ceva nu a mers bine.',
        description: (e as AxiosError)?.message,
      });
    }
  }, [page]);

  useEffect(() => {
    fetchCredits();
  }, [fetchCredits]);

  return (
    <>
      <Form.Item
        name={[item.id, lang, 'full_calculator']}
        valuePropName="checked"
        label="Calculator complet"
        style={formItemStyle}
      >
        <Checkbox
          onClick={(e) => {
            e.stopPropagation();

            setFieldsValue({
              [item.id]: {
                [lang]: {
                  full_calculator: !item.full_calculator,
                },
              },
            });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Tip"
        name={[item.id, lang, 'dataType']}
        rules={[
          {
            required: true,
            message: 'Titlul este necesar',
          },
        ]}
        style={formItemStyle}
      >
        <Select disabled={!!item.full_calculator}>
          <Select.Option value={CalculatorType.DEPOSIT}>Deposit</Select.Option>
          <Select.Option value={CalculatorType.CREDIT}>Credit</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[item.id, lang, 'action']}
        initialValue={item?.action}
        label="Acțiune"
        style={formItemStyle}
      >
        <Link lang={lang} />
      </Form.Item>

      <Form.Item
        name={[item.id, lang, 'default_type']}
        initialValue={item?.default_type}
        label="Tip implicit"
        style={formItemStyle}
      >
        <Select
          options={data?.results?.map((type) => ({
            label: type?.title,
            value: type?.id,
          }))}
        />
      </Form.Item>
    </>
  );
};
